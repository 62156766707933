import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    isLoading: false,
    isSuccess: false,
    error: null,
    data: [],
    searchConditions: null,
    skus: {
        isLoading: false,
        isSuccess: false,
        error: null,
        skuList: [],
    },
    redeemedVouchers: {
        isLoading: false,
        isSuccess: false,
        error: null,
        data: [],
    },
};
export const voucherList = createSlice({
    name: 'voucherList',
    initialState: initialState,
    reducers: {
        getPayVouchersAction: (state, action) => {
            state.isLoading = true;
            state.searchConditions = action.payload.searchConditions;
        },
        getPayVouchersSuccessAction: (state, { payload: payload }) => {
            state.data = payload;
            state.isSuccess = true;
            state.isLoading = false;
            state.error = null;
        },
        getPayVouchersErrorAction: (state, { payload: error }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.error = error;
        },
        getSkuAction: (state) => {
            state.skus.isLoading = true;
        },
        getSkuSuccessAction: (state, { payload: payload }) => {
            state.skus.isSuccess = true;
            state.skus.skuList = payload;
            state.skus.isLoading = false;
            state.skus.error = null;
        },
        getSkuErrorAction: (state, { payload: error }) => {
            state.skus.isLoading = false;
            state.skus.isSuccess = false;
            state.skus.error = error;
        },
        getRedeemedVoucherAction: (state) => {
            state.redeemedVouchers.isLoading = true;
        },
        getRedeemedVoucherSuccessAction: (state, { payload: payload }) => {
            state.redeemedVouchers.data = payload;
            state.redeemedVouchers.isSuccess = true;
            state.redeemedVouchers.isLoading = false;
            state.redeemedVouchers.error = null;
        },
        getRedeemedVoucherErrorAction: (state, { payload: error }) => {
            state.redeemedVouchers.isLoading = false;
            state.redeemedVouchers.isSuccess = false;
            state.redeemedVouchers.error = error;
        },
    },
});
export const { getPayVouchersAction, getPayVouchersSuccessAction, getPayVouchersErrorAction, getSkuAction, getSkuSuccessAction, getSkuErrorAction, getRedeemedVoucherAction, getRedeemedVoucherSuccessAction, getRedeemedVoucherErrorAction, } = voucherList.actions;
export default voucherList.reducer;
