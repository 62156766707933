import { defineMessages } from 'react-intl';
export const scope = 'app.containers.vouchers';
export default defineMessages({
    vouchers: {
        id: `${scope}.vouchers`,
        defaultMessage: 'Vouchers',
    },
    search_vouchers: {
        id: `${scope}.search_vouchers`,
        defaultMessage: 'Search for vouchers...',
    },
    empty_voucher: {
        id: `${scope}.empty_voucher`,
        defaultMessage: 'No Vouchers Yet',
    },
    empty_voucher_description: {
        id: `${scope}.empty_voucher`,
        defaultMessage: 'No vouchers have been issued at the moment.',
    },
    error_voucher_fetch_header: {
        id: `${scope}.error_voucher_fetch`,
        defaultMessage: 'Data Fetch Failed',
    },
    error_voucher_fetch_body: {
        id: `${scope}.error_voucher_fetch_body`,
        defaultMessage: 'Unable to retrieve data. Retry.',
    },
    redeemed: {
        id: `${scope}.redeemed`,
        defaultMessage: 'Redeemed',
    },
    issued: {
        id: `${scope}.issued`,
        defaultMessage: 'Issued',
    },
    create_voucher: {
        id: `${scope}.create_voucher`,
        defaultMessage: 'Create Voucher',
    },
});
