import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import Header from '@components/Base/Header';
import { triggerToast } from '@components/Base/Notification';
import SomethingWentWrong from '@components/Error/SomethingWentWrong';
import VoucherList from '@components/Vouchers';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppSelector, useAppDispatch } from '@utils/hooks';
import { proviewConsoleVoucherPageViewedEvent } from '@utils/mixpanelActions';
import { formatInitialFilters, getFormattedFilters } from './helpers';
import messages from './messages';
import { getPayVouchersAction, getSkuAction, getRedeemedVoucherAction, } from './slice';
const Vouchers = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const parseInitialFilters = formatInitialFilters(searchParams);
    const filterDetails = parseInitialFilters();
    const { filters, search } = filterDetails;
    const [searchTerm, setSearchTerm] = useState(search);
    const [selectedTab, setSelectedTab] = useState('issued');
    const [appliedFilters, updateAppliedFilters] = useState(filters);
    const { data: vouchersData, isLoading, error, skus: { skuList: skuData }, redeemedVouchers, } = useAppSelector((state) => state.voucherList);
    const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);
    const { data: redeemedVouchersList, isLoading: loadingRedeemedVouchers } = redeemedVouchers;
    const updateURLParams = (tab) => {
        setSearchParams((params) => {
            params.set('tab', tab || selectedTab);
            const filters = Object.entries(appliedFilters).reduce((acc, [key, value]) => {
                if (value) {
                    if (Array.isArray(value)
                        ? value.length > 0
                        : typeof value === 'object'
                            ? Object.keys(value).length > 0
                            : true) {
                        acc[key] = value;
                    }
                }
                return acc;
            }, {});
            if (Object.keys(filters).length > 0) {
                params.set('filters', JSON.stringify(filters));
            }
            else {
                params.delete('filters');
            }
            if (searchTerm) {
                params.set('search', searchTerm);
            }
            else {
                params.delete('search');
            }
            return params;
        }, { replace: true });
    };
    const handleSelectedTab = (value) => {
        setSelectedTab(value);
    };
    const vouchers = useMemo(() => {
        return vouchersData.map((voucher) => ({
            label: voucher.code,
            value: voucher.code,
        }));
    }, [vouchersData]);
    const skusList = useMemo(() => {
        return skuData.map((session) => ({
            label: session.name,
            value: session.id,
        }));
    }, [skuData]);
    useEffect(() => {
        const baseSearchConditions = [
            { code: { _ilike: `%${searchTerm}%` } },
            { description: { _ilike: `%${searchTerm}%` } },
        ];
        const filters = getFormattedFilters(appliedFilters);
        updateURLParams();
        dispatch(getPayVouchersAction({
            searchConditions: searchTerm ? baseSearchConditions : {},
            conditions: filters,
        }));
    }, [searchTerm, appliedFilters, selectedTab]);
    useEffect(() => {
        if (isMixpanelInitialized && user) {
            const mixpanelProperties = getMixpanelProperties(user);
            proviewConsoleVoucherPageViewedEvent(mixpanelProperties);
        }
    }, [isMixpanelInitialized, user.tenant]);
    useEffect(() => {
        if (!skuData.length) {
            dispatch(getSkuAction());
        }
    }, [skuData]);
    useEffect(() => {
        dispatch(getRedeemedVoucherAction());
    }, [selectedTab]);
    if (error) {
        triggerToast({
            variant: 'danger',
            setting: { position: 'top-right' },
            message: {
                title: _jsx(FormattedMessage, Object.assign({}, messages.error_voucher_fetch_header)),
                summary: _jsx(FormattedMessage, Object.assign({}, messages.error_voucher_fetch_body)),
            },
        });
        return _jsx(SomethingWentWrong, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Header, { details: { label: _jsx(FormattedMessage, Object.assign({}, messages.vouchers)) } }), _jsx(VoucherList, { appliedFilters: appliedFilters, updateAppliedFilters: updateAppliedFilters, voucherList: vouchersData, isLoading: isLoading, searchTerm: searchTerm, setSearchTerm: setSearchTerm, vouchers: vouchers, selectedTab: selectedTab, handleSelectedTab: handleSelectedTab, skuList: skusList, redeemedVouchersList: redeemedVouchersList, loadingRedeemedVouchers: loadingRedeemedVouchers })] }));
};
export default Vouchers;
