import mixpanel from 'mixpanel-browser';
import { mixpanelEvents, mixpanelDefaultProperties, } from '@utils/mixpanelEvents';
export const trackEvent = (event, properties) => {
    const mixpanelProperties = Object.assign(Object.assign({}, mixpanelDefaultProperties), properties);
    mixpanel.track(event, mixpanelProperties);
};
export const userLoggedInEvent = (options) => {
    trackEvent(mixpanelEvents.PROVIEW_USER_LOGGED_IN, Object.assign({}, options));
};
export const proviewUserNewProjectCreationStartedEvent = (options) => {
    trackEvent(mixpanelEvents.PROVIEW_USER_NEW_PROJECT_CREATION_STARTED, Object.assign({}, options));
};
export const proviewUserNewProjectCreatedEvent = (options) => {
    trackEvent(mixpanelEvents.PROVIEW_USER_NEW_PROJECT_CREATED, Object.assign({}, options));
};
export const proviewUserProjectPageOpenedEvent = (options) => {
    trackEvent(mixpanelEvents.PROVIEW_USER_PROJECT_PAGE_OPENED, Object.assign({}, options));
};
export const proviewUserFlowPageOpenedEvent = (options) => {
    trackEvent(mixpanelEvents.PROVIEW_USER_FLOW_PAGE_OPENED, Object.assign({}, options));
};
export const proviewUserNewFlowCreationStartedEvent = (options) => {
    trackEvent(mixpanelEvents.PROVIEW_USER_NEW_FLOW_CREATION_STARTED, Object.assign({}, options));
};
export const provieUserNewFlowCreatedEvent = (options) => {
    trackEvent(mixpanelEvents.PROVIEW_USER_NEW_FLOW_CREATED, Object.assign({}, options));
};
export const proviewUserFlowDetailPageViewedEvent = (options) => {
    trackEvent(mixpanelEvents.PROVIEW_USER_FLOW_DETAIL_PAGE_VIEWED, Object.assign({}, options));
};
export const proviewConsoleVoucherPageViewedEvent = (options) => {
    trackEvent(mixpanelEvents.PROVIEW_CONSOLE_VOUCHERS_PAGE_VIEWED, Object.assign({}, options));
};
