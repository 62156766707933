import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { isEmpty, sortBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import SearchInput from '@components/Base/Input';
import Tabs from '@components/Base/Tabs';
import messages from '@containers/vouchers/messages';
import { SELECTED_TAB, } from '@containers/vouchers/types';
import ClipBoardIcon from 'app/images/clipboard.svg';
import VoucherFilters from './VoucherFilters';
import IssuedVoucherTable from './VoucherTable/IssuedTable';
import { createColumns } from './VoucherTable/IssuedTable/columns';
import RedeemedVoucherTable from './VoucherTable/RedeemedTable';
import { createRedeemColumns } from './VoucherTable/RedeemedTable/columns';
const VoucherList = (props) => {
    const { voucherList, searchTerm, isLoading, setSearchTerm, updateAppliedFilters, vouchers, appliedFilters, selectedTab, handleSelectedTab, skuList, redeemedVouchersList, loadingRedeemedVouchers, } = props;
    const showEmptyPage = isEmpty(appliedFilters);
    const isRenderNoVouchersPage = isEmpty(voucherList) && !isLoading && !searchTerm && showEmptyPage;
    const sortedData = sortBy(redeemedVouchersList, (order) => { var _a; return (_a = order.order_items) === null || _a === void 0 ? void 0 : _a[0].coupon.code; });
    const columnsIssued = useMemo(() => {
        return createColumns().filter((column) => column !== null);
    }, [selectedTab]);
    const columnsRedeemed = useMemo(() => {
        return createRedeemColumns().filter((column) => column !== null);
    }, [selectedTab]);
    const handleSearch = (value) => {
        setSearchTerm(value);
    };
    const applyFilters = (filters) => {
        updateAppliedFilters(filters);
    };
    const handleTabSelect = (value) => {
        handleSelectedTab(value);
    };
    return (_jsx(_Fragment, { children: isRenderNoVouchersPage ? (_jsx(EmptyMessagePage, { icon: ClipBoardIcon, titleMessage: messages.empty_voucher, descriptionMessage: messages.empty_voucher_description, descriptionWidth: "w-48" })) : (_jsx("div", { className: "flex w-full px-8 py-5", children: _jsxs("div", { className: "flex flex-col w-full", children: [_jsxs("div", { className: "flex flex-row-reverse justify-between items-center mb-4", children: [_jsxs("div", { className: "flex items-center gap-4", children: [_jsx(SearchInput, { initialValue: searchTerm, handleChange: (value) => handleSearch(value), placeholderMessageId: "app.containers.vouchers.search_vouchers" }), _jsx(VoucherFilters, { updateAppliedFilters: applyFilters, vouchers: vouchers, appliedFilters: appliedFilters, skuList: skuList })] }), _jsx("div", { className: "flex items-center", children: _jsx(Tabs, { tabData: [
                                        {
                                            label: _jsx(FormattedMessage, Object.assign({}, messages.issued)),
                                            value: SELECTED_TAB.ISSUED,
                                            isShow: true,
                                        },
                                        {
                                            label: _jsx(FormattedMessage, Object.assign({}, messages.redeemed)),
                                            value: SELECTED_TAB.REDEEMED,
                                            isShow: true,
                                        },
                                    ], selectedValue: selectedTab, onSelect: handleTabSelect }) })] }), _jsx(_Fragment, { children: selectedTab === SELECTED_TAB.ISSUED ? (_jsx(IssuedVoucherTable, { data: voucherList, columns: columnsIssued, isLoading: isLoading })) : (_jsx(RedeemedVoucherTable, { data: sortedData, columns: columnsRedeemed, isLoading: loadingRedeemedVouchers })) })] }) })) }));
};
export default VoucherList;
