import isObject from 'lodash/isObject';
import some from 'lodash/some';
export const formatInitialFilters = (searchParams) => {
    return () => {
        const queryParams = {};
        for (const [key, value] of searchParams) {
            try {
                queryParams[key] = JSON.parse(decodeURIComponent(value));
            }
            catch (_a) {
                queryParams[key] = decodeURIComponent(value);
            }
        }
        return {
            filters: queryParams.filters || {},
            search: queryParams.search || '',
        };
    };
};
export const hasNonEmptyFilters = (filters) => {
    return some(filters, (value) => {
        if (isObject(value)) {
            return some(value, Boolean);
        }
        return Boolean(value);
    });
};
export const getFormattedFilters = (appliedFilters) => {
    const { selectedSku, selectedVouchers, selectedExpiresDateRange, selectedCreatedRange, } = appliedFilters;
    return Object.assign(Object.assign(Object.assign(Object.assign({}, ((selectedSku === null || selectedSku === void 0 ? void 0 : selectedSku.length) && {
        sku_coupons: {
            sku_id: { _in: selectedSku.map(({ value }) => value) },
        },
    })), ((selectedVouchers === null || selectedVouchers === void 0 ? void 0 : selectedVouchers.length) && {
        code: { _in: selectedVouchers.map(({ value }) => value) },
    })), (selectedExpiresDateRange &&
        selectedExpiresDateRange.startDate &&
        selectedExpiresDateRange.endDate && {
        valid_till: {
            _gte: selectedExpiresDateRange.startDate,
            _lte: selectedExpiresDateRange.endDate,
        },
    })), (selectedCreatedRange &&
        selectedCreatedRange.startDate &&
        selectedCreatedRange.endDate && {
        created_at: {
            _gte: selectedCreatedRange.startDate,
            _lte: selectedCreatedRange.endDate,
        },
    }));
};
